import React, { useState } from 'react'
import { Link, navigate } from 'gatsby'
import { useApi } from '~/api'
import cn from 'classnames'

import * as st from '~/assets/styl/ShoppingOptions.module.styl'

import rightArrow from '~/assets/img/svg/right-arrow.svg'
import { useMediaQuery } from 'react-responsive'
import { LazyImage } from 'react-lazy-images'
import SoldOutTag from './SoldOutTag'

type Props = {
  image: string
  name: string
  region: string
  exit_at?: string
  slug?: string
}

export default function ShoppingOptions({
  image,
  name,
  region,
  exit_at,
  slug,
}: Props) {
  const [perPageMobile, setPerPageMobile] = useState(4)
  const [perPage, setPerPage] = useState(9)
  const shoppingOptionsUnfiltered = useApi([], 'shoppings')
  const shoppingOptions = shoppingOptionsUnfiltered.filter(
    (t) => !slug || t.name === slug
  )

  if (shoppingOptionsUnfiltered.length && shoppingOptions.length === 0) {
    navigate('/404')
  }

  function loadMore() {
    setPerPage(perPage + 9)
    setPerPageMobile(perPageMobile + 4)
  }

  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })
  return (
    <div className={st.shoppingOptionsContainer}>
      <ul className={st.shoppingCardsWrapper}>
        {(!isDesktop &&
          shoppingOptions.slice(0, perPageMobile).map((card, key) => (
            <li key={key} className={st.shoppingCard}>
              <Link to={'/viagens/' + card.slug + '/'} title={card.title}>
                <div
                  className={cn(st.shoppingCardHeader, {
                    [st.backgroundMissingImage]: !card.image,
                  })}
                >
                  <LazyImage
                    src={card.image}
                    alt={card.name}
                    placeholder={({ imageProps, ref }) => (
                      <img ref={ref} src={card.image} alt={card.name} />
                    )}
                    actual={({ imageProps }) => (
                      <img {...imageProps} style={{ width: '100%' }} />
                    )}
                  />
                  {card.payment && (
                    <div className={st.installmentBar}>
                      <span>{card.payment}</span>
                    </div>
                  )}
                </div>
                <div className={st.shoppingCardBody}>
                  <div className={st.destination}>
                    <span>
                      <b>{card.name}</b> - {card.region}
                    </span>
                    {card.unavailable && <SoldOutTag />}
                  </div>
                  <div className={st.descriptions}>
                    <div className={st.departing}>
                      <img src={rightArrow} />
                      <span>Saída {card.exit_at}</span>
                    </div>
                  </div>
                </div>
              </Link>
            </li>
          ))) ||
          shoppingOptions.slice(0, perPage).map((card, key) => (
            <li key={key} className={st.shoppingCard}>
              <Link to={'/viagens/' + card.slug + '/'} title={card.title}>
                <div
                  className={cn(st.shoppingCardHeader, {
                    [st.backgroundMissingImage]: !card.image,
                  })}
                >
                  <LazyImage
                    src={card.image}
                    alt={card.name}
                    placeholder={({ imageProps, ref }) => (
                      <img ref={ref} src={card.image} alt={card.name} />
                    )}
                    actual={({ imageProps }) => (
                      <img {...imageProps} style={{ width: '100%' }} />
                    )}
                  />
                  {card.payment && (
                    <div className={st.installmentBar}>
                      <span>{card.payment}</span>
                    </div>
                  )}
                </div>
                <div className={st.shoppingCardBody}>
                  <div className={st.destination}>
                    <span>
                      <b>{card.name}</b> - {card.region}
                    </span>
                    {card.unavailable && <SoldOutTag />}
                  </div>
                  <div className={st.descriptions}>
                    <div className={st.departing}>
                      <img src={rightArrow} />
                      <span>Saída {card.exit_at}</span>
                    </div>
                  </div>
                </div>
              </Link>
            </li>
          ))}
      </ul>
      {(isDesktop && shoppingOptions.length > perPage && (
        <button
          className={cn(st.loadMore, 'hide-on-mobile')}
          onClick={loadMore}
        >
          Carregar mais pacotes
        </button>
      )) ||
        (!isDesktop && shoppingOptions.length > perPageMobile && (
          <button
            className={cn(st.loadMore, 'hide-on-desktop')}
            onClick={loadMore}
          >
            Carregar mais pacotes
          </button>
        ))}
    </div>
  )
}
