import React from 'react'
import { Head } from '~/components'
import Banner from '~/components/Banner'
import Breadcrumb from '~/components/Breadcrumb'
import ShoppingOptions from '~/components/ShoppingOptions'
import VipLounge from '~/components/VipLounge'
import Footer from '~/components/Footer'
import { Router } from '@reach/router'

import bannerCompras from '~/assets/img/banner-compras.png'

const ViagemDeCompras = () => (
  <>
    <Head title={'Viagens de Compras - ' + process.env.GATSBY_SITE_NAME} />
    <Banner
      bannerBackgroundColor="#EFC8AE"
      bannerTitle="Viagem de Compras"
      bannerSubtitle="Aproveite nossas rotas para sua viagem de compras"
      bannerImage={bannerCompras}
      bannerImageAlt="Foto de uma mulher com uma sacola de compras"
      imageBottomPosition={-1}
    />
    <Breadcrumb />
    <ShoppingOptions />
    <VipLounge />
    <Footer />
  </>
)

const ViagemDeComprasGrouped = ({ slug }) => (
  <>
    <Head title={'Viagens de Compras - ' + process.env.GATSBY_SITE_NAME} />
    <Banner
      bannerBackgroundColor="#EFC8AE"
      bannerTitle="Viagem de Compras"
      bannerSubtitle="Aproveite nossas rotas para sua viagem de compras"
      bannerImage={bannerCompras}
      bannerImageAlt="Foto de uma mulher com uma sacola de compras"
      imageBottomPosition={-1}
    />
    <Breadcrumb />
    <ShoppingOptions slug={slug} />
    <VipLounge />
    <Footer />
  </>
)

export default function ViagensDeCompras() {
  return (
    <Router>
      <ViagemDeCompras path="/viagem-de-compras" />
      <ViagemDeComprasGrouped path="/viagens-de-compras/:slug" />
    </Router>
  )
}
