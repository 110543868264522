// extracted by mini-css-extract-plugin
export var backgroundMissingImage = "ShoppingOptions-module--background-missing-image--G99FB";
export var departing = "ShoppingOptions-module--departing--ownON";
export var descriptions = "ShoppingOptions-module--descriptions--yh+v+";
export var destination = "ShoppingOptions-module--destination--QhNf8";
export var installmentBar = "ShoppingOptions-module--installment-bar--hk-Yy";
export var loadMore = "ShoppingOptions-module--load-more--jkIcZ";
export var shoppingCard = "ShoppingOptions-module--shopping-card--edocX";
export var shoppingCardBody = "ShoppingOptions-module--shopping-card-body--6T3he";
export var shoppingCardHeader = "ShoppingOptions-module--shopping-card-header--ba5Po";
export var shoppingCardsWrapper = "ShoppingOptions-module--shopping-cards-wrapper--JiK0o";
export var shoppingOptionsContainer = "ShoppingOptions-module--shopping-options-container--uzXj5";